<!-- <app-navbar [menuType]="2"></app-navbar> -->
<!-- <div class="page-container"> -->
<!-- <div class="header-bar">
    <div class="header-cont">
      <span class="header-text">
        <a routerLink="/">Anasayfa</a>  > <strong>Gizlilik Politikamız</strong>
      </span>
    </div>
  </div> -->
<!-- <app-loading *ngIf="loading"></app-loading> -->
<!-- <div class="body-container" *ngIf="!loading"> -->
<ng-container *transloco="let t; read: 'contracts.PRIVACY_POLCY'">
  <div class="mes-cnt" *ngIf="!loading">
    <p class="title">
      {{ t("TITLE") }}
    </p>
    <!-- <p class="sub-title">
      {{ t("TITLE_MES") }}
    </p> -->

    <div class="sub-mes">
      <p class="mes clr-blck">
        {{ t("SUB_TITLE_1") }}
      </p>
      <p class="mes">
        {{ t("TITLE_1_MES_1") }}
      </p>
      <p class="mes">
        {{ t("TITLE_1_MES_2") }}
      </p>

      <p class="mes clr-blck">
        {{ t("SUB_TITLE_2") }}
      </p>
      <p class="mes">
        {{ t("TITLE_2_MES_1") }}
      </p>
      <p class="mes">
        {{ t("TITLE_2_MES_2") }}
      </p>
      <p class="mes">
        {{ t("TITLE_2_MES_3") }}
      </p>
      <p class="mes">
        {{ t("TITLE_2_MES_4") }}
      </p>
      <p class="mes">
        {{ t("TITLE_2_MES_5") }}
      </p>
      <p class="mes">
        {{ t("TITLE_2_MES_6") }}
      </p>

      <p class="mes clr-blck">
        {{ t("SUB_TITLE_3") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_1") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_2") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_3") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_4") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5_1") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5_2") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5_3") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5_4") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5_5") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5_6") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5_7") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5_8") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5_9") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_5_MORE") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_1") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_2") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_3") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_4") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_5") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_6") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_7") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_8") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_9") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_10") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_11") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_12") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_13") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_14") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_6_MORE") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_7") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_8") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_9") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_10") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_11") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_12") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_13") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_14") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_15") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_16") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_17") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_18") }}
      </p>
      <p class="mes">
        {{ t("TITLE_3_MES_19") }}
      </p>
      <p class="mes clr-blck">
        {{ t("SUB_TITLE_4") }}
      </p>
      <p class="mes">
        {{ t("TITLE_4_MES_1") }}
      </p>
      <p class="mes">
        {{ t("TITLE_4_MES_2") }}
      </p>
      <p class="mes">
        {{ t("TITLE_4_MES_3") }}
      </p>
      <p class="mes">
        {{ t("TITLE_4_MES_4") }}
      </p>
      <p class="mes clr-blck">
        {{ t("SUB_TITLE_5") }}
      </p>
      <p class="mes">
        {{ t("TITLE_5_MES_1") }}
      </p>
      <p class="mes">
        {{ t("TITLE_5_MES_2") }}
      </p>
      <p class="mes">
        {{ t("TITLE_5_MES_3") }}
      </p>
      <p class="mes">
        {{ t("TITLE_5_MES_4") }}
      </p>
      <p class="mes clr-blck">
        {{ t("SUB_TITLE_6") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_1") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_2") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_3") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_4") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_5") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_6") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_7") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_8") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_9") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_10") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_11") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_12") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_13") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_14") }}
      </p>
      <p class="mes">
        {{ t("TITLE_6_MES_15") }}
      </p>
    </div>
    <!-- </div> -->
  </div>
  <!-- </div> -->
  <!-- <app-footer></app-footer> -->
</ng-container>
