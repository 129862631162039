import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TermsOfServiceComponent } from "./terms-of-service/terms-of-service.component";
// import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { HttpClientModule } from "@angular/common/http";
import { TranslocoRootModule } from "./transloco-root.module";
import { NZ_I18N } from "ng-zorro-antd/i18n";
import { en_US } from "ng-zorro-antd/i18n";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { MatExpansionModule } from "@angular/material/expansion";

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    TermsOfServiceComponent,
    // MobileMenuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    MatExpansionModule,
  ],
  providers: [FormBuilder, { provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent],
})
export class AppModule {}
